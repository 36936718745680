<template>
  <!--
    The view for Customer Contacts
  -->
  <div class="installationDetail_InstallationContacts">
    <template>
      <div class="card">
        <Portlet
          :title="$t('installationDetailOperationView.contacts')"
          icon="user-friends"
        >
          <template slot="buttons">
            <button 
              v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel'))"
              class="btn btn-sm btn-primary float-right mt-2 mb-2 mr-2" 
              @click="clearCache()"
            >
              <font-awesome-icon
                class="mr-"
                icon="trash"
              />
              <span>
                Clear Contact Cache
              </span>
            </button>
            <button 
              v-if="authenticationHasRole('scope_customer') && !authenticationHasRole('secondlevel')"
              class="btn btn-sm btn-primary float-right mt-2 mb-2" 
              @click="showSidebarAdd"
            >
              <font-awesome-icon
                class="mr-1"
                icon="plus"
              />
              <span>
                {{ $t('addContact') }}
              </span>
            </button>
          </template>
          <InstallationContactsList
            ref="installationContactsList"
            :installation-id="installationId"
          />
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "InstallationDetailInstallationContacts",
  components: {
    InstallationContactsList : () => import('@/components/Installation/InstallationContactsList.vue')
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: 'Contacts'
    }
  },
  methods: {
    showSidebarAdd () {
      this.$refs.installationContactsList.showSidebarAdd();
    },
    clearCache () {
      this.$refs.installationContactsList.clearCache();
    },
  }
}
</script>